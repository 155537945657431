/*
 * @Author: your name
 * @Date: 2021-12-10 17:46:25
 * @LastEditTime: 2021-12-28 16:39:06
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/api/MySettings/index.js
 */
import httpService from "@/request"

// 获取用户信息

// 获取业务联系信息
export function userInfo(params) {
    return httpService({    
        url: `/user/salesman/contact`,
        method: 'post',
        data: params,
    })
}

// 密码重置
export function resetPwd(params) {
    return httpService({    
        url: `/user/reset_pwd`,
        method: 'post',
        data: params,
    })
}
// 获取用户的详情
export function uInfo(params) {
    return httpService({    
        url: `/user/info`,
        method: 'post',
        data: params,
    })
}