<!--
 * @Author: your name
 * @Date: 2021-12-10 18:04:26
 * @LastEditTime: 2021-12-10 18:27:16
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/MySettings/curd/modifyPwd.vue
-->
<template>
    <div>
        <a-form-model ref="pwdForm" :model="form" :rules="rules">
            <a-form-model-item label="原密码" prop="oldPassword" :rules="validate({name: 'value', msg: '请输入原密码'})">
                <a-input type="password" allowClear v-model="form.oldPassword" placeholder="原密码"></a-input>
            </a-form-model-item>
            <a-form-model-item label="新密码" prop="newPassword">
                <a-input type="password" allowClear v-model="form.newPassword" placeholder="新密码"></a-input>
            </a-form-model-item>
            <a-form-model-item label="确认密码" prop="confirmPassword">
                <a-input type="password" allowClear v-model="form.confirmPassword" placeholder="确认密码"></a-input>
            </a-form-model-item>
        </a-form-model>
    </div>
</template>

<script>
import {resetPwd} from "@/api/MySettings"
export default {
    data() {
        let validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入密码"));
            } else {
                if (this.form.confirmPassword !== "") {
                    this.$refs.pwdForm.validateField("confirmPassword");
                }
                callback();
            }
        };
        let validatePass2 = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value !== this.form.newPassword) {
                callback(new Error("2次密码不一致"));
            } else {
                callback();
            }
        };
        return {
            form: {
                oldPassword: "",
                newPassword: "",
                confirmPassword: ""
            },
            rules: {
                newPassword: [{ validator: validatePass, trigger: "change" }],
                confirmPassword: [{ validator: validatePass2, trigger: "change" }],
            },
        };
    },
    methods:{
        submit(){
            this.$refs.pwdForm.validate((valid) => {
                if (valid) {
                    resetPwd(this.form).then(() => {
                         this.$message.success('密码重置成功');
                         this.$emit('success')
                    })
                } else {
                    console.log("error submit!!");
                    return false;
                }
            }); 
        }
    }
};
</script>

<style lang="less" scoped>
</style>
