<!--
 * @Author: your name
 * @Date: 2021-12-10 17:42:40
 * @LastEditTime: 2021-12-10 18:14:34
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/MySettings/curd/phone.vue
-->
<template>
    <div>
        <a-form-model ref="pForm" :model="form">
            <a-form-model-item label="联系人姓名" prop="name" :rules="validate({name: 'value', msg: '请输入联系人姓名'})">
                <a-input disabled allowClear v-model="form.name" placeholder="联系人姓名"></a-input>
            </a-form-model-item>
            <a-form-model-item label="联系人手机" prop="phone" :rules="validate({name: 'value', msg: '请输入联系人手机'})">
                <a-input disabled allowClear v-model="form.phone" placeholder="联系人手机"></a-input>
            </a-form-model-item>
        </a-form-model>
    </div>
</template>

<script>
import { userInfo } from "@/api/MySettings";
export default {
    data() {
        return {
            form: {
                name:"",
                phone:""
            },
        };
    },
    created() {
        this.getUser();
    },
    methods: {
        getUser() {
            userInfo().then((res) => {
                this.form = res.data;
            });
        },
    },
};
</script>

<style lang="less" scoped>
</style>