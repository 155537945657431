<!--
 * @Author: your name
 * @Date: 2021-11-22 15:56:04
 * @LastEditTime: 2021-12-28 16:43:59
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Waybill/index.vue
-->
<template>
    <div class="ms-container">
       <img width="100%" src="../../assets/images/banner.png" />
       <div class="ms-d1">
           <div class="ms-d1-s1">您好！</div>
           <div class="ms-d1-s2">{{company}}</div>
       </div>
       <div class="ms-d2">
           <div class="ms-item" @click="openPhone">
               <img src="../../assets/images/ms-1.png" />
               <div class="s1">业务联系方式</div>
           </div>
           <div class="ms-item" @click="openPwd">
               <img src="../../assets/images/ms-2.png" />
               <div class="s1">修改账号密码</div>
           </div>
           <div class="ms-item" @click="goProduct">
               <img src="../../assets/images/ms-3.png" />
               <div class="s1">产品库</div>
           </div>
           <div class="ms-item" @click="goAddress('1')">
               <img src="../../assets/images/ms-4.png"/>
               <div class="s1">取件地址</div>
           </div>
           <div class="ms-item" @click="goAddress('2')">
               <img src="../../assets/images/ms-5.png"/>
               <div class="s1">常用收件地址</div>
           </div>
       </div>
       <a-modal :title="modal.title" :visible="modal.visible" :maskClosable="false" :width="modal.width" @cancel="handlerCancel" @ok="handlerOk">
           <phone v-if="modal.index === 1 && modal.visible"></phone>
           <modifyPwd ref="mfp" v-if="modal.index === 2 && modal.visible" @success="modal.visible = false"></modifyPwd>
       </a-modal>
    </div>
</template>

<script>
import phone from "./curd/phone.vue"
import modifyPwd from "./curd/modifyPwd.vue"
import {uInfo} from "@/api/MySettings"
export default {
    name: "MySettings",
    components: {
        phone,
        modifyPwd
    },
    data(){
        return{
           company: "",
           modal:{
              title: "",
              visible: false,
              width: '400px',
              index: 0
           } 
        }
    },
    created() {
        this.getInfo()
    },
    methods:{
        openPhone(){
            this.modal.index = 1;
            this.modal.title = "业务联系方式";
            this.modal.visible = true;
        },
        openPwd(){
            this.modal.index = 2;
            this.modal.title = "修改账号密码";
            this.modal.visible = true;
        },
        handlerCancel() {
            this.modal.visible = false;
        },
        handlerOk() {
            if(this.modal.index === 1) {
                this.modal.visible = false;
            } else if(this.modal.index === 2) {
                this.$refs.mfp.submit()
            }  
        },
        goProduct() {
            this.$router.push("/ConfigInfo/Product");
        },
        goAddress(n) {
            this.setAddressIndex(n);
            this.$router.push("/ConfigInfo/Address");
        },
        getInfo() {
            uInfo().then(res => {
                this.company = res.data.company;
            })
        }
    }
}
</script>

<style lang="less" scoped>
.ms-container {
    width: 100%;
    height: 100%;
    background: #eff2f5;
    .ms-d1 {
        width: 100%;
        height: 90px;
        background: #fff;
        margin-top: 15px;
        border-radius: 10px;
        border-top:1px solid transparent;
        .ms-d1-s1 {
            color: #FB4702;
            font-size: 22px;
            margin-left: 44px;
            margin-top: 16px;
        }
        .ms-d1-s2 {
            font-size: 18px;
            margin-left: 44px;
            margin-top: 0px;
        }
    }
    .ms-d2 {
        width: 100%;
        height: 150px;
        background: #fff;
        margin-top: 16px;
        border-radius: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .ms-item {
            width: 20%;
            text-align: center;
            border-right: 1px solid #E9E9E9;
            cursor: pointer;
            .m1 {
                width: 48px;
                height: 48px;
            }
            .s1{
                margin-top: 10px;
            }
        }
    }
}
</style>
